'use client';

import type { ReactNode } from 'react';
import { useState } from 'react';
import type { Locale } from '@fixter/i18n/locale';
import { Card } from '@/components/atoms/Card';
import { Img } from '@/components/atoms/Img';
import { VrmLookupDumb } from '@/components/molecules/VrmLookupDumb';
import type { VrmLookupModalTranslations } from '@/components/molecules/VrmLookupDumbModal/translations';
import type { VrmLookupFormTranslations } from '@/components/molecules/VrmLookupDumb/translations';
import { VrmLookupDumbModal } from '@/components/molecules/VrmLookupDumbModal';

import styles from './index.module.scss';

interface ServiceCardProps {
  readonly label: string;
  readonly icon: {
    url: string;
    alt: string;
    width?: number;
    height?: number;
  };
  readonly price?: string;
  readonly sku: string;
  readonly locale: Locale;
  readonly campaignId?: string;
  readonly modalTranslations: VrmLookupModalTranslations;
  readonly formTranslations: VrmLookupFormTranslations;
}

export const ServiceCard = function ServiceCard({
  label,
  icon,
  price,
  sku,
  campaignId,
  locale,
  modalTranslations,
  formTranslations,
}: ServiceCardProps): ReactNode {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Card className={styles.card} cornerRadius={2} elevation={2} onClick={() => setShowModal(true)}>
        <Img
          provider="cloudinary"
          src={icon.url}
          alt={icon.alt}
          width={icon.width || 48}
          height={icon.height || 48}
        />
        <p className={styles.label}>{label}</p>
        {price && <p className={styles.price}>{price}</p>}
      </Card>
      <VrmLookupDumbModal
        className={styles.toggleButton}
        showModal={showModal}
        onClose={() => setShowModal(false)}
        triggeredFrom={`services-overview-cards:${sku}`}
        {...modalTranslations}
      >
        <VrmLookupDumb campaignId={campaignId} locale={locale} sku={sku} {...formTranslations} />
      </VrmLookupDumbModal>
    </>
  );
};
