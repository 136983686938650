'use client';

import classNames from 'classnames';
import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import React from 'react';
import { formatAmountWithCurrency } from '@fixter/i18n';
import { ActiveExperiments } from '$util/abTest/experiments';
import { useActiveExperiment } from '$util/abTest';
import { useAppContext } from '$util/AppContext';
import type { AppContextGlobal } from '$util/AppContextGlobal';
import { getSegmentFromLocale } from '$util/getSegment';
import { ContentComponentSection } from '@/components/atoms/ContentComponentSection';
import type { ComparisonTableRow, ComparisonTableService } from '@/components/molecules/ComparisonTable';
import { ComparisonTable } from '@/components/molecules/ComparisonTable';
import { Col, Row } from '@/components/atoms/Grid';
import { Card } from '@/components/atoms/Card';
import { ServiceCard } from '@/components/molecules/ServiceCard';
import { FixieInfo } from '@/components/molecules/FixieInfo';
import { Button, DesignType } from '@/components/atoms/Button';
import { FixieCard } from '@/components/molecules/FixieCard';
import { CompareOffersModal } from '@/components/molecules/CompareOffersModal';
import { HeadingLevels } from '@/components/atoms/HeadingLevels';
import type { VrmLookupTranslations } from '../VrmLookup';
import styles from './index.module.scss';

export interface LinkToPageProps {
  readonly linkToPageContent: string;
  readonly linkToPage: string;
  readonly linkToPageCTAText: string;
}

export interface ServiceData extends ComparisonTableService {
  readonly info: ReactNode;
  readonly description: ReactNode;
}

enum CompareServicesContentVariant {
  VIDANGE_PAGE_REVISION_CONSTRUCTEUR = 'CompareServices.vidange-v2',
  VIDANGE_PAGE_REVISION_SIMPLE = 'CompareServices.vidange',
  REVISION_PAGE_REVISION_CONSTRUCTEUR = 'CompareServices.revision-v2',
  REVISION_PAGE_REVISION_SIMPLE = 'CompareServices.revision',
}

/**
 * We want to prevent rendering when the experiment expects different content than what is provided by the CMS.
 * Contentful provides each variants of the content for a given component. Based on the experiment branch we need to render the correct content.
 * e.g. Don't render Revision Constructeur when the experiment is inactive or the original variant is active (Revision Simple).
 */
function shouldPreventRendering(useRevisionConstructeur: boolean, contentComponentId: string): boolean {
  // Active experiment B variant (Revision Constructeur)
  if (
    useRevisionConstructeur &&
    contentComponentId === CompareServicesContentVariant.VIDANGE_PAGE_REVISION_SIMPLE
  ) {
    return true;
  }
  // Inactive experiment or active original variant (Revision Simple)
  if (
    !useRevisionConstructeur &&
    contentComponentId === CompareServicesContentVariant.VIDANGE_PAGE_REVISION_CONSTRUCTEUR
  ) {
    return true;
  }
  // Active experiment B variant (Revision Constructeur)
  if (
    useRevisionConstructeur &&
    contentComponentId === CompareServicesContentVariant.REVISION_PAGE_REVISION_SIMPLE
  ) {
    return true;
  }
  // Inactive experiment or active original variant (Revision Simple)
  if (
    !useRevisionConstructeur &&
    contentComponentId === CompareServicesContentVariant.REVISION_PAGE_REVISION_CONSTRUCTEUR
  ) {
    return true;
  }
  return false;
}

function LinkToLP({ linkToPageContent, linkToPage, linkToPageCTAText }: LinkToPageProps) {
  return (
    <Row className={styles.linkSection}>
      <Col medium={6} extraLarge={1} className={styles.spacing}>
        &nbsp;
      </Col>
      <Col extraSmall={4} small={6} medium={12} extraLarge={10}>
        <FixieCard className={styles.fixieCard}>
          <div className={styles.fixieCardContent}>
            <h6>{linkToPageContent}</h6>
            <Button
              size="L"
              designType={DesignType.SECONDARY_DARK}
              href={linkToPage}
              className={classNames(styles.accessPageDesktop, styles.accessPage)}
            >
              {linkToPageCTAText}
            </Button>
            <Button
              size="M"
              designType={DesignType.SECONDARY_DARK}
              href={linkToPage}
              className={classNames(styles.accessPageMobile, styles.accessPage)}
            >
              {linkToPageCTAText}
            </Button>
          </div>
        </FixieCard>
      </Col>
      <Col medium={6} extraLarge={1} className={styles.spacing}>
        &nbsp;
      </Col>
    </Row>
  );
}

export interface CompareServicesProps extends ComponentPropsWithoutRef<'div'> {
  id: string;
  heading?: string;
  ourOfferText: string;
  mostPopularText: string;
  popularText: string;
  startingFormText: string;
  getQuoteText: string;
  compareOffersText: string;
  linkToPage?: string;
  linkToPageContent?: string;
  linkToPageCTAText?: string;
  services: ServiceData[];
  lineItems: ComparisonTableRow[];
  modalTitle: ReactNode;
  modalCta: string;
  modalToggleText: string;
  bgColor?: string;
  vrmLookupTranslations: VrmLookupTranslations;
}

export function CompareServices({
  id,
  heading,
  ourOfferText,
  mostPopularText,
  popularText,
  startingFormText,
  getQuoteText,
  compareOffersText,
  linkToPage,
  linkToPageContent,
  linkToPageCTAText,
  services,
  lineItems,
  modalTitle,
  modalCta,
  modalToggleText,
  bgColor,
  vrmLookupTranslations,
  className,
  children,
  ...props
}: CompareServicesProps): ReactNode {
  const consolidatedOtherProps = props;
  const { locale } = useAppContext<AppContextGlobal>();
  const segment = getSegmentFromLocale(locale);

  const comparisonTableTranslations = {
    ourOffer: ourOfferText,
    mostPopular: mostPopularText,
    startingFrom: startingFormText,
    getQuote: getQuoteText,
    modalTitle,
    modalCta,
    modalToggleText,
    vrmLookupTranslations,
  };

  const labelColors = [styles.labelColor1, styles.labelColor2, styles.labelColor3];

  const isABTestRevisionConstructeurFR = useActiveExperiment(ActiveExperiments.RevisionConstructeurFR, 'B');
  if (shouldPreventRendering(isABTestRevisionConstructeurFR, id)) return null;

  if (bgColor) {
    consolidatedOtherProps.style = {
      backgroundColor: bgColor,
    };
  }

  return (
    <ContentComponentSection
      id={id}
      className={classNames(CompareServices.displayName, styles.component, className)}
      {...props}
    >
      {heading && (
        <Row>
          <Col>
            <HeadingLevels className={styles.heading} semanticLevel={2} styleLevel={4}>
              {heading}
            </HeadingLevels>
          </Col>
        </Row>
      )}
      <Row className={styles.comparisonTableDesktop}>
        <Col medium={6} extraLarge={1} className={styles.spacing}>
          &nbsp;
        </Col>
        <Col extraSmall={4} small={6} medium={12} extraLarge={10}>
          <Card elevation={1} cornerRadius={3} className={styles.comparisonTableCard}>
            <ComparisonTable
              translations={comparisonTableTranslations}
              services={services}
              lineItems={lineItems}
              segment={segment}
            />
          </Card>
        </Col>
        <Col medium={6} extraLarge={1} className={styles.spacing}>
          &nbsp;
        </Col>
      </Row>
      <Row className={styles.serviceMobile}>
        <Col className={styles.servicesSection}>
          <h4>{ourOfferText}</h4>
          <div className={styles.servicesCards}>
            {services.map(({ name, price, popular, info, description }, index) => (
              <ServiceCard
                heading={name}
                label={popular ? popularText : ''}
                labelColor={labelColors[index]}
                key={name}
              >
                <div className={styles.servicesContent}>
                  <FixieInfo className={styles.fixieInfo}>{info}</FixieInfo>
                  <div className={styles.description}>{description}</div>
                  <div className={styles.price}>
                    {startingFormText}
                    <span>{formatAmountWithCurrency(price, segment, 0)}</span>
                  </div>
                </div>
              </ServiceCard>
            ))}
          </div>
          <CompareOffersModal
            translations={{ ...comparisonTableTranslations, compareOffersText }}
            services={services}
            lineItems={lineItems}
            segment={segment}
          />
        </Col>
      </Row>
      {linkToPageContent && linkToPage && linkToPageCTAText && (
        <LinkToLP
          linkToPageContent={linkToPageContent}
          linkToPage={linkToPage}
          linkToPageCTAText={linkToPageCTAText}
        />
      )}
    </ContentComponentSection>
  );
}
CompareServices.displayName = 'CompareServices';
