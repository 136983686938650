import type { HTMLAttributes } from 'react';
import React from 'react';
import classNames from 'classnames';
import type { HeadingLevelsProps } from './types';

export * from './types';

interface HeadingProps extends HTMLAttributes<HTMLHeadElement> {
  readonly as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

function Heading({ as = 'h1', children, ...props }: HeadingProps): JSX.Element {
  const Element = as;
  return <Element {...props}>{children}</Element>;
}

export function HeadingLevels({
  semanticLevel,
  styleLevel,
  children,
  className,
  ...props
}: HeadingLevelsProps): JSX.Element {
  return (
    <Heading
      as={`h${semanticLevel}` as HeadingProps['as']}
      className={classNames(styleLevel && `h${styleLevel}`, className)}
      {...props}
    >
      {children}
    </Heading>
  );
}
HeadingLevels.displayName = 'HeadingLevels';
