'use client';

import { useModalQueue } from '$util/hooks/useModalQueue';
import { Icon } from '@/components/atoms/Icon';
import type { ReactChildrenProps } from '@/types/react';

import styles from './index.module.scss';

export function InfoModal({ children }: ReactChildrenProps): JSX.Element {
  const [, queueModal] = useModalQueue();

  return (
    <Icon
      className={styles.InfoModalIcon}
      id="general/help-circle"
      width={16}
      legacy={false}
      onClick={() => {
        queueModal([
          <aside key="modal-content" className={styles.InfoModalDrawer}>
            {children}
          </aside>,
          'modal-drawer',
        ]).trackModalOpen('fixter-savings-info');
      }}
    />
  );
}
