'use client';

import type { ReactNode, SyntheticEvent } from 'react';
import React from 'react';
import classNames from 'classnames';
import { Locale } from '@fixter/i18n/locale';
import { ContentComponentSection } from '@/components/atoms/ContentComponentSection';
import { ServiceCard } from '@/components/molecules/ServiceCard';
import { Col, Row } from '@/components/atoms/Grid';
import { SectionDivider } from '@/components/atoms/SectionDivider';
import { Icon } from '@/components/atoms/Icon';
import { HeadingLevels } from '@/components/atoms/HeadingLevels';
import { VrmLookupModal } from '@/components/organisms/VrmLookupModal';
import type { VrmLookupTranslations } from '@/components/molecules/VrmLookup';
import { DesignType } from '@/components/atoms/Button';

import styles from './index.module.scss';

export interface CardItem {
  readonly name: React.ReactNode;
  readonly price: string;
  readonly icon: string;
  readonly colorIndex: number;
  readonly cta: string;
  readonly activeTab: string;
}

export interface OurServicesProps extends React.ComponentPropsWithoutRef<'div'> {
  readonly locale: Locale;
  readonly heading: string;
  readonly cardItems: CardItem[];

  readonly modalTitle: ReactNode;
  readonly modalCta: string;
  readonly vrmLookupTranslations: VrmLookupTranslations;
}

const makeHeadingValue = (name: React.ReactNode, icon: string): React.ReactNode => (
  <>
    <Icon
      id={icon}
      width={32}
      height={32}
      legacy={false}
      className={classNames(styles.headingIcon, styles.headingIconDesktop)}
    />
    <Icon
      id={icon}
      width={24}
      height={24}
      legacy={false}
      className={classNames(styles.headingIcon, styles.headingIconMobile)}
    />
    {name}
  </>
);

const makeCtaValue = (cta: string): React.ReactNode => (
  <>
    <span className={styles.ctaDesktop}>{cta}</span>
    <span className={styles.ctaMobile}>
      <Icon id="arrows/arrow-circle-broken-right" width={24} height={24} legacy={false} />
    </span>
  </>
);

const handleClick = (event: SyntheticEvent) => {
  event.stopPropagation();
  const { target } = event;
  (target as HTMLElement).closest(`.${styles.serviceCardCol}`)?.querySelector('button')?.click();
};

export function OurServices({
  locale,
  heading,
  cardItems,
  modalTitle,
  modalCta,
  vrmLookupTranslations,
  className,
  children,
  ...props
}: OurServicesProps): ReactNode {
  const colorsArray = [styles.color1, styles.color2, styles.color3, styles.color4];

  return (
    <ContentComponentSection
      className={classNames(OurServices.displayName, styles.component, className)}
      {...props}
    >
      <Row>
        <Col>
          {locale !== Locale.FR_FR && <SectionDivider />}
          <HeadingLevels
            semanticLevel={2}
            className={classNames(styles.heading, locale === Locale.FR_FR && styles.headingFr)}
          >
            {heading}
          </HeadingLevels>
        </Col>
      </Row>
      <Row>
        {cardItems.map(({ name, price, icon, colorIndex, cta, activeTab }) => (
          <Col
            key={activeTab}
            extraSmall={2}
            small={3}
            medium={6}
            large={3}
            className={styles.serviceCardCol}
            data-testid={name}
          >
            <ServiceCard
              heading={makeHeadingValue(name, icon)}
              labelColor={colorsArray[colorIndex - 1]}
              className={classNames(styles[`serviceCard${colorIndex}`], styles.ServiceCard)}
              onClick={handleClick}
            >
              <div className={styles.price}>{price}</div>
              <VrmLookupModal
                id="OurServicesCta"
                toggleButton={{
                  children: makeCtaValue(cta),
                  className: styles.cta,
                  size: 'XS',
                  designType: DesignType.SECONDARY_DARK,
                  noIcon: true,
                }}
                heading={modalTitle}
                ctaText={modalCta}
                formProps={{ translations: vrmLookupTranslations, activeTab }}
              />
            </ServiceCard>
          </Col>
        ))}
      </Row>
    </ContentComponentSection>
  );
}
OurServices.displayName = 'OurServices';
