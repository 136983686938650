export enum CheckoutPage {
  OVERVIEW = 'overview',
  QUOTES = 'quotes',
  PRODUCT = 'product',
  SCHEDULE = 'schedule',
  PERSONAL_DETAILS = 'personaldetails',
  BUNDLE = 'bundle',
  ADDONS = 'addons',
  PAYMENT = 'payment',
  PAYMENT_RETURN = 'paymentReturn',
  UPDATE_PAYMENT = 'updatePayment',
  UPDATE_PAYMENT_RETURN = 'updatePaymentReturn',
  UPDATE_PAYMENT_SUCCESS = 'updatePaymentSuccess',
  SUCCESS = 'success',
}

export enum CustomerPage {
  EWO_PAYMENT_RETURN = 'ewoPaymentReturn',
  EWO_PROPOSED = 'ewoProposed',
  EWO_CONFIRM = 'ewoConfirm',
  EWO_SUCCESS = 'ewoSuccess',
  REAUTHORISE_KLARNA_PAYMENT = 'reauthorisePayment',
  REAUTHORISE_KLARNA_PAYMENT_RETURN = 'reauthorisePaymentReturn',
}

export enum Service {
  FULL_SERVICE = 'full-service',
  REVISION_SIMPLE = 'revision-simple', // FR full-service
  REVISION_CONSTRUCTEUR = 'revision-constructeur', // Replaces revision-simple (FR)
  MAJOR_SERVICE = 'major-service',
  REVISION_COMPLETE = 'revision-complete', // FR major-service
  INTERIM_SERVICE = 'interim-service',
  VIDANGE = 'vidange', // FR interim-service
}

export enum MotSku {
  MOT = 'mot',
  MOT_PREMIUM = 'mot-premium',
  CONTROLE_TECHNIQUE = 'controle-technique',
  CONTROLE_TECHNIQUE_PREMIUM = 'controle-technique-premium',
}

export enum DiagnosticSku {
  DIAGNOSTIC = 'diagnostic',
  DIAGNOSTIC_AUTOMOBILE = 'diagnostic-automobile',
}

export enum UpsellItem {
  FULL_SERVICE = 'full-service',
  VIDANGE = 'vidange',
}

export interface PageMeta {
  readonly name: string;
  readonly slug: string;
  readonly type: string;
  readonly id?: string;
  readonly parent?: string;
  readonly sku?: string;
  readonly experimentsIds?: string;
  readonly lowestPrice?: string;
  readonly campaignId?: string;
  readonly popularity?: number;
  readonly priority?: boolean;
  readonly isFree?: boolean;
  readonly robots?: boolean;
  readonly geoMeta?: string;
  readonly prices?: {
    readonly mot: string;
  };
}

export enum SearchSuggestionsType {
  PRODUCT = 'product',
  CAR = 'car',
}

/**
 * There are no officialy types for KeyboardEvent key just yet.
 * Picked only the keys that we use to not install the entire package.
 * @see https://gitlab.com/nfriend/ts-key-enum/-/blob/master/Key.enum.d.ts
 * @see https://www.w3.org/TR/uievents-key/#named-key-attribute-values
 */
export enum KeyboardEventKey {
  Enter = 'Enter',
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
  Backspace = 'Backspace',
  Clear = 'Clear',
  Delete = 'Delete',
  Escape = 'Escape',
}

export enum ProductReviewsType {
  MOT = 'mot',
  DIAGNOSTIC = 'diagnostic',
  REPAIRS = 'repairs',
  ANY = 'any',
}

/**
 * This type is for generic functions that would accept any object structure.
 * Don't use it to avoid specifying the actual type a function expects.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyObject = Record<string, any>;

/**
 * @deprecated use from type-fest
 */
export type EmptyObject = Record<string, never>;

/**
 * Modify some fields of a interface with another type.
 *
 * eg.
 * ```
 * Modify<{ a: string, b: string}, { b: boolean }> = { a: string, b: boolean}
 * ```
 */
export type Modify<T, R> = Omit<T, keyof R> & R;

/**
 * Recursivelly transform the types inside object to be nullable.
 */
export type NullableDeep<T> = T extends object ? { [P in keyof T]: NullableDeep<T[P]> } : T | null;

/**
 * Recursivelly transform the types inside object to be nullable or undefined.
 */
export type PartialNullableDeep<T> = T extends object
  ? { [P in keyof T]?: PartialNullableDeep<T[P]> }
  : T | null;

/**
 * Describes image data.
 * Use in components that shouldn't directly depend on the CMS types (eg. atoms).
 * `ImageData` -> `CmsImage`
 */
export interface ImageData {
  readonly url: string;
  readonly width: number;
  readonly height: number;
  readonly alt: string;
  readonly title?: string;
}

/**
 * Describes link data.
 * Use in components that shouldn't directly depend on the CMS types (eg. atoms).
 * `LinkData` -> `CmsLink`
 */
export interface LinkData {
  readonly text: string;
  readonly url: string;
}

export enum Urgency {
  ADVISORY = 'advisory',
  MINOR = 'minor',
  MAJOR = 'major',
  DANGEROUS = 'dangerous',
}

export enum VirtualSourceTypes {
  MotBundle = 'MOT-BUNDLE',
  CTBundle = 'CT-BUNDLE',
}

export enum CardStatus {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
  COMPLETED = 'completed',
  SKIP = 'skip',
}

export enum CollectionDeliveryTypes {
  GARAGE = 'GarageC&D',
  FIXTER = 'Fixter',
  CUSTOMER = 'CustomerC&D',
  OUT_OF_COVERAGE = 'OutOfCoverage',
  UNKNOWN = 'Unknown',
}

export enum UrlParamSourceTypes {
  AbandonedBasketEmail = 'abandonedBasketEmail',
  DeclinedEwoReminderEmail = 'declinedEwoReminderEmail',
}
