'use client';

import { useState, type ReactNode } from 'react';
import type { Locale } from '@fixter/i18n';
import { Card } from '@/components/atoms/Card';
import type { ReactPropsWithChildren } from '@/types/react';
import { VrmLookupDumb } from '@/components/molecules/VrmLookupDumb';
import type { VrmLookupFormTranslations } from '@/components/molecules/VrmLookupDumb/translations';
import type { VrmLookupModalTranslations } from '@/components/molecules/VrmLookupDumbModal/translations';
import { VrmLookupDumbModal } from '@/components/molecules/VrmLookupDumbModal';

import styles from './index.module.scss';

type OverviewDiagnosticClientProps = ReactPropsWithChildren<{
  readonly sku: string;
  readonly locale: Locale;
  readonly modalTranslations: VrmLookupModalTranslations;
  readonly formTranslations: VrmLookupFormTranslations;
  readonly campaignId?: string;
  readonly className?: string;
}>;

export function OverviewDiagnosticClient({
  sku,
  campaignId,
  locale,
  children,
  modalTranslations,
  formTranslations,
  className,
}: OverviewDiagnosticClientProps): ReactNode {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Card className={className} elevation={2} cornerRadius={2} onClick={() => setShowModal(true)}>
        {children}
      </Card>
      <VrmLookupDumbModal
        className={styles.toggleButton}
        showModal={showModal}
        onClose={() => setShowModal(false)}
        triggeredFrom="diagnostic-overview-card"
        {...modalTranslations}
      >
        <VrmLookupDumb campaignId={campaignId} locale={locale} sku={sku} {...formTranslations} />
      </VrmLookupDumbModal>
    </>
  );
}
